@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

$light-theme: mat.define-theme();
$dark-theme: mat.define-theme(
	(
		color: (
			theme-type: dark,
		),
	)
);

:root {
	--text-primary: hsl(226.25, 46.15%, 20.39%);
	--text-secondary: hsl(231.22, 17.9%, 44.9%);
}

:is(.dark) {
	--text-primary: hsl(226.25, 46.15%, 89.61%);
	--text-secondary: hsl(231.22, 17.9%, 65.1%);
}

@font-face {
	font-family: 'Inter Tight';
	src: url(/assets/fonts/Inter-Tight/InterTight-VariableFont_wght.ttf) format('truetype');
}

* {
	font-family: 'Inter Tight';
	font-size: 15px;
	@apply border-gray-200 dark:border-gray-700;
}

body {
	@apply bg-white dark:bg-gray-900;
	@apply font-medium text-[var(--text-primary)];
}

input {
	background: transparent;

	&:focus-within {
		outline: none;
	}
}

.table-container {
	@apply border rounded-2xl relative overflow-x-auto bg-white dark:bg-gray-900/75;
}

table {
	thead {
		tr {
			@apply text-[var(--text-secondary)];

			th {
				@apply p-4 max-lg:px-2 text-left text-[13px] font-medium; // defaut value is bold
			}
		}
	}

	td {
		@apply p-4 max-lg:px-2 align-middle;
	}

	tbody {
		tr {
			@apply border-t;
		}
	}

	td:empty {
		@apply text-[var(--text-secondary)];

		&::before {
			content: '—';
		}
	}
}

// Remove spinner from number input
@layer base {
	input[type='number'] {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}

.mhp-separator {
	@apply my-12 h-px border-t-0
		opacity-25
		bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent;

	&:is(.dark *) {
		@apply via-neutral-400;
	}
}

.icon-container {
	transition:
		transform 0.5s ease,
		filter 0.5s ease;

	& > ng-icon:hover {
		transform: scale(1.1); /* Zoom légèrement */
		filter: drop-shadow(0 0 5px #999); /* Rend l'icône plus lumineuse et ajoute un effet de brillance */
		&:is(.dark *) {
			filter: brightness(150%) drop-shadow(0 0 5px #fff);
		}
	}
}

// .icon-container {
// 	@apply transition-all duration-500 ease-in-out;

// 	& > ng-icon:hover {
// 		@apply transform scale-125 filter brightness-150 dark:drop-shadow-lg;
// 	}
// }
